import Hero from '../components/Hero'
import Services from '../components/Services'
import About from '../components/About'
import Why from '../components/Why'
import Skills from '../components/Skills'
import Portfolio from '../components/Portfolio'
import Banner from '../components/Banner'
import FAQ from '../components/FAQ'
import ContactForm from '../components/ContactForm'

const HomePage = () => {
  return (
    <>
      <Hero />
      <About />
      <Services />
      <Why />
      <Skills />
      <Portfolio />
      <Banner />
      <ContactForm />
      <FAQ />
    </>
  )
}

export default HomePage
