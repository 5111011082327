import React from 'react'

const Banner = () => {
  return (
    <div className="bg-[#F0EFEA] rounded-xl mx-1 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-12 flex flex-col justify-center items-center text-center gap-6 p-10 mt-[40px] mb-[20px]">
      <h2 className="text-primary text-2xl lg:text-4xl font-bold w-full md:w-[80%] lg:w-[60%]">
        Transform Your Online Presence with a Stunning Website
      </h2>
      <p className="text-primary/70 text-xs lg:text-sm font-base w-full md:w-[80%] lg:w-[60%]">
        Your website is your digital storefront. Make a lasting impression with
        a custom, high-performance site designed to captivate your audience and
        drive business growth. At Vedid, we specialize in creating tailored
        websites that elevate your brand and deliver exceptional user
        experiences
      </p>
      <button
        type="button"
        className="px-3 py-2 bg-primary text-tertiary hover:bg-secondary font-medium rounded-lg transition-all duration-300"
        onClick={() =>
          window.open('https://calendly.com/vedidinfo16/30min', '_blank')
        }
      >
        Become A Client
      </button>
    </div>
  )
}

export default Banner
