import About from '../components/About'
import Services from '../components/Services'
import Skills from '../components/Skills'
import Portfolio from '../components/Portfolio'
import ContactForm from '../components/ContactForm'

const AboutPage = () => {
  return (
    <>
      <About />
      <Services />
      <Skills />
      <Portfolio />
      <ContactForm />
    </>
  )
}

export default AboutPage
