import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'
import { FaBehance, FaDribbble, FaPinterest, FaXTwitter } from 'react-icons/fa6'
import { navItems } from '../constants'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
      <div className="p-10 mx-1 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-12 flex flex-col mt-[40px] bg-primary text-white rounded-t-xl">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
            <div className="mb-5">
              <h4 className="text-2xl font-bold">Vedid.</h4>
              <p className="text-sm mt-2">
                Specialize in developing high-performing, user-friendly websites
                tailored to your brand's unique needs.
              </p>
              <div className="flex flex-col justify-center items-start gap-8 text-2xl mt-8">
                <div className="flex justify-center items-center gap-4">
                  <FaFacebook
                    className="text-blue-600 cursor-pointer hover:-translate-y-1 transition-all duration-300"
                    onClick={() =>
                      window.open(
                        'https://www.facebook.com/lokesh.ak.1428',
                        '_blank'
                      )
                    }
                  />
                  <FaInstagram
                    className="text-pink-700 cursor-pointer hover:-translate-y-1 transition-all duration-300"
                    onClick={() =>
                      window.open(
                        'https://www.instagram.com/vedid._in/',
                        '_blank'
                      )
                    }
                  />
                  <FaLinkedin
                    className="text-blue-600 cursor-pointer hover:-translate-y-1 transition-all duration-300"
                    onClick={() =>
                      window.open(
                        'https://www.linkedin.com/in/lokeshg24092002/',
                        '_blank'
                      )
                    }
                  />
                  <FaYoutube
                    className="text-red-500 cursor-pointer hover:-translate-y-1 transition-all duration-300"
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/@vedid._in',
                        '_blank'
                      )
                    }
                  />
                </div>
                <div className="flex justify-center items-center gap-4">
                  <FaXTwitter
                    className="text-white cursor-pointer hover:-translate-y-1 transition-all duration-300"
                    onClick={() =>
                      window.open('https://x.com/vedidin', '_blank')
                    }
                  />
                  <FaDribbble
                    className="text-pink-500 cursor-pointer hover:-translate-y-1 transition-all duration-300"
                    onClick={() =>
                      window.open('https://dribbble.com/vedid', '_blank')
                    }
                  />
                  <FaBehance
                    className="text-blue-600 cursor-pointer hover:-translate-y-1 transition-all duration-300"
                    onClick={() =>
                      window.open(
                        'https://www.behance.net/lokeshg1703',
                        '_blank'
                      )
                    }
                  />
                  <FaPinterest
                    className="text-red-600 cursor-pointer hover:-translate-y-1 transition-all duration-300"
                    onClick={() =>
                      window.open(
                        'https://www.pinterest.com/vedidinfo16/',
                        '_blank'
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mb-5 text-left lg:text-center">
              <h4 className="text-xl font-bold text-secondary">Quick Links</h4>
              <ul className="flex flex-col justify-between gap-2 mt-4">
                {navItems.map((item, index) => (
                  <li
                    key={index}
                    className="text-[16px] text-white hover:text-secondary transition-all duration-300"
                  >
                    <Link to={item.href}>{item.label}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-5 text-left lg:text-center">
              <h4 className="text-xl font-bold text-secondary">Our Services</h4>
              <ul className="flex flex-col justify-between gap-2 mt-4">
                <li className="text-[16px] text-white hover:text-secondary transition-all duration-300">
                  <a href="/services">Wordpress</a>
                </li>
                <li className="text-[16px] text-white hover:text-secondary transition-all duration-300">
                  <a href="/services">Shopify</a>
                </li>
                <li className="text-[16px] text-white hover:text-secondary transition-all duration-300">
                  <a href="/services">Webflow</a>
                </li>
                <li className="text-[16px] text-white hover:text-secondary transition-all duration-300">
                  <a href="/services">SEO</a>
                </li>
                <li className="text-[16px] text-white hover:text-secondary transition-all duration-300">
                  <a href="/services">UI/UX</a>
                </li>
              </ul>
            </div>
            <div className="mb-5">
              <h4 className="text-xl font-bold text-secondary">Contact</h4>
              <div className="flex flex-col justify-center items-start mt-4">
                <p className="text-base">
                  <span className="font-semibold">Call:</span> +916384328151
                </p>
                <p>
                  <span className="font-semibold">Email:</span>{' '}
                  vedidinfo16@gmail.com
                </p>
                <p>
                  <span className="font-semibold">Location:</span> Bargur,
                  Krishnagiri, TamilNadu, India
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
