import React, { useRef } from 'react'
import {
  FaLocationDot,
  FaPhone,
  FaRegEnvelope,
  FaBehance,
  FaDribbble,
  FaXTwitter,
  FaPinterest,
} from 'react-icons/fa6'
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'
import emailjs from 'emailjs-com'

const ContactForm = () => {
  const form = useRef()

  const sendEmail = async (e) => {
    e.preventDefault()
    try {
      const result = await emailjs.sendForm(
        'service_0cvdfsk', // Your EmailJS service ID
        'template_yrzmi27', // Your EmailJS template ID
        form.current,
        'W42Zh3ay8TsVu4T29' // Your EmailJS user ID (public key)
      )
      if (result.text === 'OK') {
        alert('Message sent successfully!')
      }
    } catch (error) {
      console.error('Error sending email:', error.text)
      alert('Failed to send message. Please try again later.')
    }
  }

  return (
    <div className="mx-1 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-12 mb-8 mt-10 grid grid-cols-1 lg:grid-cols-2 bg-primary/10 rounded-xl">
      {/* <div className="space-y-6 p-6">
        <h2 className="text-primary text-2xl font-semibold">Get In Touch</h2>
        <p className="text-gray-500">
          Have a question or need a custom quote? Contact us today, and we’ll
          get back to you as soon as possible.
        </p>
        <div className="space-y-4">
          <div className="flex items-center">
            <FaPhone className="mr-2 text-primary text-xl p-2 bg-white rounded-full" />
            <span className="text-primary text-lg">+916384328151</span>
          </div>
          <div className="flex items-center">
            <FaRegEnvelope className="mr-2 text-primary text-xl p-2 bg-white rounded-full" />
            <span className="text-primary text-lg">vedidinfo16@gmail.com</span>
          </div>
          <div className="flex items-center">
            <FaLocationDot className="mr-2 text-primary text-xl p-2 bg-white rounded-full" />
            <span className="text-primary text-lg">
              Bargur, Krishnagiri, TamilNadu
            </span>
          </div>
        </div>
        <div
          className="bg-white flex justify-start items-center px-4 py-1 rounded-md gap-4 cursor-pointer"
          onClick={() =>
            window.open('https://www.instagram.com/vedid._in/', '_blank')
          }
        >
          <FaInstagram className="text-pink-600 hover:-translate-y-1 transition-all duration-300" />
          <p className="text-lg">Instagram</p>
        </div>
        <div
          className="bg-white flex justify-start items-center px-4 py-1 rounded-md gap-4 cursor-pointer"
          onClick={() =>
            window.open(
              'https://www.linkedin.com/in/lokeshg24092002/',
              '_blank'
            )
          }
        >
          <FaLinkedin className="text-blue-600 hover:-translate-y-1 transition-all duration-300" />
          <p className="text-lg">LinkedIn</p>
        </div>
      </div> */}

      <div className="space-y-6 p-6">
        <h2 className="text-primary text-2xl font-semibold">Get In Touch</h2>
        <p className="text-gray-500">
          Have a question or need a custom quote? We’re here to help! Contact us
          today, and our team will get back to you as soon as possible.
        </p>
        <div className="space-y-4">
          <div className="flex items-center">
            <FaPhone className="mr-2 text-primary text-xl p-2 h-fit w-fit bg-white rounded-full" />
            <span className="text-primary text-lg">+916384328151</span>
          </div>
          <div className="flex items-center">
            <FaRegEnvelope className="mr-2 text-primary text-xl p-2 h-fit w-fit bg-white rounded-full" />
            <span className="text-primary text-lg">vedidinfo16@gmail.com</span>
          </div>
          <div className="flex items-center">
            <FaLocationDot className="mr-2 text-primary text-xl p-2 h-fit w-fit bg-white rounded-full" />
            <span className="text-primary text-lg">
              Bargur, Krishnagiri,TamilNadu
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-4 items-start">
          <div className="flex justify-center items-center gap-2">
            <div className="w-[20px] h-[2px] bg-secondary rounded-full"></div>
            <p className="text-lg font-light text-primary">Follow Us On</p>
          </div>
          <div className="justify-center items-center gap-4 text-2xl grid grid-cols-1 sm:grid-cols-2">
            <div
              className="bg-white flex justify-start items-center px-4 py-1 rounded-md gap-4"
              onClick={() =>
                window.open('https://www.facebook.com/lokesh.ak.1428', '_blank')
              }
            >
              <FaFacebook className="text-blue-600 cursor-pointer hover:-translate-y-1 transition-all duration-300" />
              <p className="text-lg cursor-pointer">Facebook</p>
            </div>
            <div
              className="bg-white flex justify-start items-center px-4 py-1 rounded-md gap-4"
              onClick={() =>
                window.open('https://www.instagram.com/vedid._in/', '_blank')
              }
            >
              <FaInstagram className="text-pink-600 cursor-pointer hover:-translate-y-1 transition-all duration-300" />
              <p className="text-lg cursor-pointer">Instagram</p>
            </div>
            <div
              className="bg-white flex justify-start items-center px-4 py-1 rounded-md gap-4"
              onClick={() =>
                window.open(
                  'https://www.linkedin.com/in/lokeshg24092002/',
                  '_blank'
                )
              }
            >
              <FaLinkedin className="text-blue-600 cursor-pointer hover:-translate-y-1 transition-all duration-300" />
              <p className="text-lg cursor-pointer">LinkedIn</p>
            </div>
            <div
              className="bg-white flex justify-start items-center px-4 py-1 rounded-md gap-4"
              onClick={() =>
                window.open('https://www.youtube.com/@vedid._in', '_blank')
              }
            >
              <FaYoutube className="text-red-500 cursor-pointer hover:-translate-y-1 transition-all duration-300" />
              <p className="text-lg cursor-pointer">Youtube</p>
            </div>
            <div
              className="bg-white flex justify-start items-center px-4 py-1 rounded-md gap-4"
              onClick={() => window.open('https://x.com/vedidin', '_blank')}
            >
              <FaXTwitter className="text-primary cursor-pointer hover:-translate-y-1 transition-all duration-300" />
              <p className="text-lg cursor-pointer">Twitter</p>
            </div>
            <div
              className="bg-white flex justify-start items-center px-4 py-1 rounded-md gap-4"
              onClick={() =>
                window.open('https://dribbble.com/vedid', '_blank')
              }
            >
              <FaDribbble className="text-pink-500 cursor-pointer hover:-translate-y-1 transition-all duration-300" />
              <p className="text-lg cursor-pointer">Dribble</p>
            </div>
            <div
              className="bg-white flex justify-start items-center px-4 py-1 rounded-md gap-4"
              onClick={() =>
                window.open('https://www.behance.net/lokeshg1703', '_blank')
              }
            >
              <FaBehance className="text-blue-600 cursor-pointer hover:-translate-y-1 transition-all duration-300" />
              <p className="text-lg cursor-pointer">Behance</p>
            </div>
            <div
              className="bg-white flex justify-start items-center px-4 py-1 rounded-md gap-4"
              onClick={() =>
                window.open('https://www.pinterest.com/vedidinfo16/', '_blank')
              }
            >
              <FaPinterest className="text-red-600 cursor-pointer hover:-translate-y-1 transition-all duration-300" />
              <p className="text-lg cursor-pointer">Pinterest</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary shadow-lg rounded-lg m-2">
        <form
          ref={form}
          onSubmit={sendEmail}
          className="space-y-4 flex flex-col items-center px-2 py-4"
        >
          <div className="w-[90%]">
            <label className="block text-tertiary font-semibold mb-2">
              Name
            </label>
            <input
              type="text"
              name="name"
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
          </div>
          <div className="w-[90%]">
            <label className="block text-tertiary font-semibold mb-2">
              Email
            </label>
            <input
              type="email"
              name="email"
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
          </div>
          <div className="w-[90%]">
            <label className="block text-tertiary font-semibold mb-2">
              Phone Number
            </label>
            <input
              type="tel"
              name="phone"
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
          </div>
          <div className="w-[90%]">
            <label className="block text-tertiary font-semibold mb-2">
              Message
            </label>
            <textarea
              name="message"
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              rows="4"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-[90%] bg-secondary text-white font-semibold py-3 rounded-lg hover:bg-primary-dark focus:outline-none"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
