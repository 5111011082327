import { FaPlus } from 'react-icons/fa6'

const Services = () => {
  return (
    <div className="mx-1 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-12 flex flex-col pt-[40px] pb-[20px] gap-4 lg:gap-10">
      <div className="flex flex-col items-center text-center gap-4">
        <h2 className="px-2 text-2xl leading-10 md:text-4xl font-bold md:leading-[80px] text-primary">
          Our Expert Web Development Services
        </h2>
        <p className="w-full md:w-[70%] px-2 text-sm md:text-lg leading-6 text-gray-500">
          We offer a full suite of web development and digital services to bring
          your brand online with style, functionality, and optimal performance.
          From custom WordPress sites to immersive UI/UX designs, we've got you
          covered.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 py-10">
        <div className="bg-primary p-6 rounded-xl min-h-[350px] items-center">
          <div className="h-full bg-[#ECF2F0] rounded-lg p-6 flex flex-col items-center justify-between">
            <h2 className="text-2xl font-semibold text-center text-primary">
              Custom Website
            </h2>
            <p className="text-sm font-light text-gray-500 text-center">
              We build custom, scalable websites tailored to your unique
              business needs, ensuring optimal performance and user experience
            </p>
            <div
              className="w-12 h-12 rounded-full bg-white flex justify-center items-center shadow-lg cursor-pointer"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              <FaPlus className="text-primary" />
            </div>
          </div>
        </div>
        <div className="bg-primary p-6 rounded-xl min-h-[350px] items-center">
          <div className="h-full bg-[#ECF2F0] rounded-lg p-6 flex flex-col items-center justify-between">
            <h2 className="text-2xl font-semibold text-center text-primary">
              Wordpress Website
            </h2>
            <p className="text-sm font-light text-gray-500 text-center">
              Our WordPress solutions deliver secure, responsive websites
              designed for easy management and scalability
            </p>
            <div
              className="w-12 h-12 rounded-full bg-white flex justify-center items-center shadow-lg cursor-pointer"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              <FaPlus className="text-primary" />
            </div>
          </div>
        </div>
        <div className="bg-primary p-6 rounded-xl min-h-[350px] items-center">
          <div className="h-full bg-[#ECF2F0] rounded-lg p-6 flex flex-col items-center justify-between">
            <h2 className="text-2xl font-semibold text-center text-primary">
              Webflow Website
            </h2>
            <p className="text-sm font-light text-gray-500 text-center">
              We create dynamic, visually stunning Webflow websites with
              responsive designs and seamless animations
            </p>
            <div
              className="w-12 h-12 rounded-full bg-white flex justify-center items-center shadow-lg cursor-pointer"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              <FaPlus className="text-primary" />
            </div>
          </div>
        </div>
        <div className="bg-primary p-6 rounded-xl min-h-[350px] items-center">
          <div className="h-full bg-[#ECF2F0] rounded-lg p-6 flex flex-col items-center justify-between">
            <h2 className="text-2xl font-semibold text-center text-primary">
              E-commerce Website
            </h2>
            <p className="text-sm font-light text-gray-500 text-center">
              Our end-to-end eCommerce services help you build secure, scalable
              online stores that drive sales and customer engagement
            </p>
            <div
              className="w-12 h-12 rounded-full bg-white flex justify-center items-center shadow-lg cursor-pointer"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              <FaPlus className="text-primary" />
            </div>
          </div>
        </div>
        <div className="bg-primary p-6 rounded-xl min-h-[350px] items-center">
          <div className="h-full bg-[#ECF2F0] rounded-lg p-6 flex flex-col items-center justify-between">
            <h2 className="text-2xl font-semibold text-center text-primary">
              Shopify Store
            </h2>
            <p className="text-sm font-light text-gray-500 text-center">
              Launch a powerful Shopify store with our custom development
              services focused on boosting sales and enhancing user experience
            </p>
            <div
              className="w-12 h-12 rounded-full bg-white flex justify-center items-center shadow-lg cursor-pointer"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              <FaPlus className="text-primary" />
            </div>
          </div>
        </div>
        <div className="bg-primary p-6 rounded-xl min-h-[350px] items-center">
          <div className="h-full bg-[#ECF2F0] rounded-lg p-6 flex flex-col items-center justify-between">
            <h2 className="text-2xl font-semibold text-center text-primary">
              UI/UX Design
            </h2>
            <p className="text-sm font-light text-gray-500 text-center">
              We craft intuitive, user-centered UI/UX designs to enhance
              engagement and improve conversion rates
            </p>
            <div
              className="w-12 h-12 rounded-full bg-white flex justify-center items-center shadow-lg cursor-pointer"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              <FaPlus className="text-primary" />
            </div>
          </div>
        </div>
        <div className="bg-primary p-6 rounded-xl min-h-[350px] items-center">
          <div className="h-full bg-[#ECF2F0] rounded-lg p-6 flex flex-col items-center justify-between">
            <h2 className="text-2xl font-semibold text-center text-primary">
              SEO
            </h2>
            <p className="text-sm font-light text-gray-500 text-center">
              Optimize your website’s visibility with our comprehensive SEO
              services, driving more traffic and improving search rankings
            </p>
            <div
              className="w-12 h-12 rounded-full bg-white flex justify-center items-center shadow-lg cursor-pointer"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              <FaPlus className="text-primary" />
            </div>
          </div>
        </div>
        <div className="bg-primary p-6 rounded-xl min-h-[350px] items-center">
          <div className="h-full bg-[#ECF2F0] rounded-lg p-6 flex flex-col items-center justify-between">
            <h2 className="text-2xl font-semibold text-center text-primary">
              3D Website
            </h2>
            <p className="text-sm font-light text-gray-500 text-center">
              Elevate your digital experience with immersive 3D website designs
              that captivate and engage visitors
            </p>
            <div
              className="w-12 h-12 rounded-full bg-white flex justify-center items-center shadow-lg cursor-pointer"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              <FaPlus className="text-primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
