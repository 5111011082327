import React from 'react'
import { FaPlus } from 'react-icons/fa6'

const FAQ = () => {
  return (
    <div className="mx-1 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-12 flex flex-col py-[20px] gap-4 lg:gap-10">
      <div className="flex flex-col items-center text-center gap-4">
        <h2 className="px-2 text-2xl leading-10 md:text-4xl font-bold md:leading-[80px] text-primary">
          Frequently Asked Questions (FAQ's)
        </h2>
        <p className="w-full md:w-[70%] px-2 text-sm md:text-lg leading-6 text-gray-500">
          Got questions about our website development services? We’ve got
          answers. Below are some common questions to help you understand our
          process and offerings better. If you need more information, feel free
          to reach out!
        </p>
      </div>
      <div className="flex flex-col justify-center items-center gap-4">
        <details className="group w-full md:w-[80%] lg:w-[50%] px-4">
          <summary className="text-lg sm:text-xl cursor-pointer text-primary font-medium">
            What types of websites do you develop?
          </summary>
          <p className="text-gray-500 pl-10 md:pl-6 mt-2 sm:text-[16px] text-[12px]">
            We specialize in a wide range of websites including custom websites,
            WordPress sites, Shopify eCommerce stores, Webflow designs, and
            immersive 3D websites. Whether you need a portfolio site, an online
            store, or a complex business platform, we have the expertise to
            bring your vision to life.
          </p>
        </details>
        <details className="group w-full md:w-[80%] lg:w-[50%] px-4">
          <summary className="text-lg sm:text-xl cursor-pointer text-primary font-medium">
            How long does it take to develop a website?
          </summary>
          <p className="text-gray-500 pl-10 md:pl-6 mt-2 sm:text-[16px] text-[12px]">
            The timeline for website development depends on the complexity and
            features of the project. A basic website can take 5-7 days, while
            more complex eCommerce or custom websites may take 2-4 weeks. We
            work closely with you to set realistic deadlines and ensure timely
            delivery
          </p>
        </details>
        <details className="group w-full md:w-[80%] lg:w-[50%] px-4">
          <summary className="text-lg sm:text-xl cursor-pointer text-primary font-medium">
            Do you provide maintenance after the website launch?
          </summary>
          <p className="text-gray-500 pl-10 md:pl-6 mt-2 sm:text-[16px] text-[12px]">
            Yes, we offer maintenance packages to ensure your website runs
            smoothly after launch. Our services include regular updates,
            security checks, performance optimization, and troubleshooting as
            needed.
          </p>
        </details>
        <details className="group w-full md:w-[80%] lg:w-[50%] px-4">
          <summary className="text-lg sm:text-xl cursor-pointer text-primary font-medium">
            Will my website be SEO-friendly and mobile-responsive?
          </summary>
          <p className="text-gray-500 pl-10 md:pl-6 mt-2 sm:text-[16px] text-[12px]">
            Absolutely! We prioritize SEO best practices and mobile
            responsiveness in all our projects. This means your website will be
            optimized for search engines and will provide a seamless experience
            on both desktop and mobile devices.
          </p>
        </details>
        <details className="group w-full md:w-[80%] lg:w-[50%] px-4">
          <summary className="text-lg sm:text-xl cursor-pointer text-primary font-medium">
            Can you help with redesigning my existing website?
          </summary>
          <p className="text-gray-500 pl-10 md:pl-6 mt-2 sm:text-[16px] text-[12px]">
            Yes, we offer website redesign services to enhance the look,
            functionality, and performance of your existing site.
          </p>
        </details>
      </div>
    </div>
  )
}

export default FAQ
