const Skills = () => {
  return (
    <div className="mx-1 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-12 my-4 lg:my-6 grid gird-cols-1 lg:grid-cols-2 bg-[#ECF2F0] rounded-xl">
      <div className="w-[90%] flex flex-col items-start p-4 md:p-6 lg:p-8 gap-4">
        <h2 className="px-2 text-2xl leading-10 md:text-4xl font-bold md:leading-[80px] text-primary">
          Skills That Set Us Apart
        </h2>
        <div className="flex flex-col gap-2">
          <p className="px-2 text-sm md:text-lg leading-6 text-gray-500">
            At Vedid, we combine industry-leading tools and technologies to
            deliver exceptional website development and design services. Our
            diverse skill set in platforms like WordPress, Shopify, and Webflow,
            paired with creative design capabilities in Figma and 3D modeling in
            Blender, allows us to craft unique, high-quality digital solutions
            that make your brand stand out
          </p>
          {/* <p className="px-2 text-sm md:text-lg leading-6 text-gray-500">
            Our mission is to empower businesses with exceptional online
            experiences, driving engagement and boosting conversions through
            cutting-edge web development and design
          </p> */}
        </div>
        <button
          className="ml-2 mt-6 px-3 py-2 bg-primary text-tertiary hover:bg-secondary font-medium rounded-lg transition-all duration-300"
          onClick={() =>
            window.open('https://calendly.com/vedidinfo16/30min', '_blank')
          }
        >
          Start Your Project
        </button>
      </div>
      <div className="w-full flex flex-col justify-center items-center py-8 p-4 md:p-6 lg:p-8 gap-8">
        <div className="flex flex-col gap-1 items-start w-full px-3">
          <div className="w-full flex justify-between items-center">
            <p>Wordpress</p>
            <p>95%</p>
          </div>
          <div className="bg-primary/10 h-2 w-full rounded-full">
            <div className="bg-secondary h-full w-[95%] rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col gap-1 items-start w-full px-3">
          <div className="w-full flex justify-between items-center">
            <p>Webflow</p>
            <p>90%</p>
          </div>
          <div className="bg-primary/10 h-2 w-full rounded-full">
            <div className="bg-secondary h-full w-[90%] rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col gap-1 items-start w-full px-3">
          <div className="w-full flex justify-between items-center">
            <p>Shopify</p>
            <p>90%</p>
          </div>
          <div className="bg-primary/10 h-2 w-full rounded-full">
            <div className="bg-secondary h-full w-[90%] rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col gap-1 items-start w-full px-3">
          <div className="w-full flex justify-between items-center">
            <p>Figma</p>
            <p>85%</p>
          </div>
          <div className="bg-primary/10 h-2 w-full rounded-full">
            <div className="bg-secondary h-full w-[85%] rounded-full"></div>
          </div>
        </div>
        <div className="flex flex-col gap-1 items-start w-full px-3">
          <div className="w-full flex justify-between items-center">
            <p>Blender</p>
            <p>80%</p>
          </div>
          <div className="bg-primary/10 h-2 w-full rounded-full">
            <div className="bg-secondary h-full w-[80%] rounded-full"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skills
