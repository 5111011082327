import React from 'react'
import { FaAward, FaCode, FaHandshake } from 'react-icons/fa6'

const Why = () => {
  return (
    <div className="mx-1 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-12 flex flex-col py-[20px] gap-4 lg:gap-10">
      <div className="flex flex-col items-center text-center gap-4">
        <h2 className="px-2 text-2xl leading-10 md:text-4xl font-bold md:leading-[80px] text-primary">
          Why To Choose <span className="text-secondary">Vedid</span> ?
        </h2>
        <p className="w-full md:w-[70%] px-2 text-sm md:text-lg leading-6 text-gray-500">
          At Vedid, we are dedicated to helping businesses achieve their digital
          goals through tailored, high-quality web solutions. Here are three key
          reasons why we stand out as your ideal web development partner
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-10">
        <div className="flex flex-col justify-start gap-4 p-6 rounded-lg bg-[#F0EFEA]">
          <div className="w-12 h-12 rounded-full bg-white flex justify-center items-center shadow-lg">
            <FaAward className="text-primary text-xl" />
          </div>
          <h2 className="text-2xl font-semibold text-start text-primary">
            Expertise & Experience
          </h2>
          <p className="text-sm font-light text-gray-500 text-start w-[80%]">
            We bring extensive experience and deep industry knowledge to deliver
            top-notch, scalable solutions
          </p>
        </div>
        <div className="flex flex-col justify-start gap-4 p-6 rounded-lg bg-[#E6E0EA]">
          <div className="w-12 h-12 rounded-full bg-white flex justify-center items-center shadow-lg">
            <FaHandshake className="text-primary text-2xl" />
          </div>
          <h2 className="text-2xl font-semibold text-start text-primary">
            Client-Centric Approach
          </h2>
          <p className="text-sm font-light text-gray-500 text-start w-[80%]">
            We prioritize your needs, working closely with you to create a
            custom website that aligns perfectly with your vision and business
            goals
          </p>
        </div>
        <div className="flex flex-col justify-start gap-4 p-6 rounded-lg bg-[#CAECE6]">
          <div className="w-12 h-12 rounded-full bg-white flex justify-center items-center shadow-lg">
            <FaCode className="text-primary text-xl" />
          </div>
          <h2 className="text-2xl font-semibold text-start text-primary">
            Cutting-Edge Technologies
          </h2>
          <p className="text-sm font-light text-gray-500 text-start w-[80%]">
            We use the latest technologies and best practices, ensuring your
            website is fast, secure, and optimized for top performance and SEO
          </p>
        </div>
      </div>
    </div>
  )
}

export default Why
