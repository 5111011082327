import Portfolio from '../components/Portfolio'
import Skills from '../components/Skills'
import ContactForm from '../components/ContactForm'

const PortfolioPage = () => {
  return (
    <>
      <Portfolio />
      <Skills />
      <ContactForm />
    </>
  )
}

export default PortfolioPage
