import Services from '../components/Services'
import Portfolio from '../components/Portfolio'
import Why from '../components/Why'
import ContactForm from '../components/ContactForm'

const ServicePage = () => {
  return (
    <>
      <Services />
      <Why />
      <Portfolio />
      <ContactForm />
    </>
  )
}

export default ServicePage
