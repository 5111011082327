import { FaBehance, FaDribbble, FaPinterest, FaXTwitter } from 'react-icons/fa6'
import heroImage from '../assets/rb_5921.png'
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'

const Hero = () => {
  return (
    <div className="mx-1 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-12 my-4 lg:my-6 grid gird-cols-1 lg:grid-cols-2">
      <div className="w-full flex flex-col items-center justify-center lg:items-start text-center lg:text-start gap-4">
        <h1 className="text-[26px] leading-10 md:text-6xl font-bold md:leading-[80px] text-primary ">
          Build Custom Websites
          <br />
          To Elevate Your Business
        </h1>
        <p className="text-sm md:text-lg leading-6 text-gray-500">
          We specialize in developing high-performing, user-friendly websites
          tailored to your brand's unique needs. We ensure a seamless online
          experience to boost engagement and drive conversions.
        </p>
        <button
          className="px-3 py-2 bg-primary text-tertiary hover:bg-secondary font-medium rounded-lg transition-all duration-300"
          onClick={() =>
            window.open('https://calendly.com/vedidinfo16/30min', '_blank')
          }
        >
          Start Your Project
        </button>
        <div className="flex flex-col gap-4 items-center lg:items-start">
          <div className="flex justify-center items-center gap-2">
            <div className="w-[20px] h-[2px] bg-secondary rounded-full"></div>
            <p className="text-lg font-light text-primary">Follow Us On</p>
          </div>
          <div className="flex justify-center items-center gap-4 text-2xl">
            <FaFacebook
              className="text-blue-600 cursor-pointer hover:-translate-y-1 transition-all duration-300"
              onClick={() =>
                window.open('https://www.facebook.com/lokesh.ak.1428', '_blank')
              }
            />
            <FaInstagram
              className="text-pink-700 cursor-pointer hover:-translate-y-1 transition-all duration-300"
              onClick={() =>
                window.open('https://www.instagram.com/vedid._in/', '_blank')
              }
            />
            <FaLinkedin
              className="text-blue-600 cursor-pointer hover:-translate-y-1 transition-all duration-300"
              onClick={() =>
                window.open(
                  'https://www.linkedin.com/in/lokeshg24092002/',
                  '_blank'
                )
              }
            />
            <FaYoutube
              className="text-red-500 cursor-pointer hover:-translate-y-1 transition-all duration-300"
              onClick={() =>
                window.open('https://www.youtube.com/@vedid._in', '_blank')
              }
            />
            <FaXTwitter
              className="text-primary cursor-pointer hover:-translate-y-1 transition-all duration-300"
              onClick={() => window.open('https://x.com/vedidin', '_blank')}
            />
            <FaDribbble
              className="text-pink-500 cursor-pointer hover:-translate-y-1 transition-all duration-300"
              onClick={() =>
                window.open('https://dribbble.com/vedid', '_blank')
              }
            />
            <FaBehance
              className="text-blue-600 cursor-pointer hover:-translate-y-1 transition-all duration-300"
              onClick={() =>
                window.open('https://www.behance.net/lokeshg1703', '_blank')
              }
            />
            <FaPinterest
              className="text-red-600 cursor-pointer hover:-translate-y-1 transition-all duration-300"
              onClick={() =>
                window.open('https://www.pinterest.com/vedidinfo16/', '_blank')
              }
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center">
        <img
          src={heroImage}
          alt="Website Development Agency"
          className="w-[80%]"
        />
      </div>
    </div>
  )
}

export default Hero
