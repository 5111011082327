import aboutImage from '../assets/About.png'

const About = () => {
  return (
    <div className="mx-1 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-12 my-4 lg:my-6 grid gird-cols-1 lg:grid-cols-2 bg-[#ECF2F0] rounded-xl">
      <div className="w-full flex justify-center items-center py-8">
        <img src={aboutImage} alt="Our Team" className="w-[80%]" />
      </div>
      <div className="w-[90%] flex flex-col items-start py-8">
        <h2 className="px-2 text-2xl leading-10 md:text-4xl font-bold md:leading-[80px] text-primary">
          About <span className="text-secondary">Vedid</span>
        </h2>
        <div className="flex flex-col gap-2">
          <p className="px-2 text-sm md:text-lg leading-6 text-gray-500">
            At Vedid, we are passionate about helping businesses thrive online
            through exceptional website design and development. We specialize in
            creating high-performance websites that not only look great but also
            drive user engagement and conversions. From small startups to
            established enterprises, we bring a client-focused approach,
            ensuring every project is tailored for success. Trust us to be your
            digital partner in turning your vision into reality
          </p>
          <p className="px-2 text-sm md:text-lg leading-6 text-gray-500">
            Our mission is to empower businesses with exceptional online
            experiences, driving engagement and boosting conversions through
            cutting-edge web development and design
          </p>
        </div>
        <button
          className="ml-2 mt-6 px-3 py-2 bg-primary text-tertiary hover:bg-secondary font-medium rounded-lg transition-all duration-300"
          onClick={() =>
            window.open('https://calendly.com/vedidinfo16/30min', '_blank')
          }
        >
          Start Your Project
        </button>
      </div>
    </div>
  )
}

export default About
